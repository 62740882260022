<!--
File: InventoryTypeEditForm.vue
Description: form for adding/editing a single inventory type.
-->
<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon">
        <div class="card-icon"> <md-icon>perm_identity</md-icon> </div>
        <h4 class="title"> {{ $t(`route.inventory_type_${oper}`) }} </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field :class="[
                { 'md-valid': !errors.has('description') },
                { 'md-error': errors.has('description') }
              ]">
                <label>{{ $t('stdCols.name_ru') }}</label>
                <md-input v-model="description" type="text" data-vv-name="description" required
                  v-validate="modelValidations.description"></md-input>
              </md-field>

              <md-field :class="[
                { 'md-valid': !errors.has('description_en') },
                { 'md-error': errors.has('description_en') }
              ]">
                <label>{{ $t('stdCols.name_en') }}</label>
                <md-input v-model="description_en" type="text" data-vv-name="description_en" required
                  v-validate="modelValidations.description_en"></md-input>
              </md-field>

              <InvTypeEventDropdown :label="$t('label.inventory_event')" v-model.number="fk_inventory_type_event"
                data-vv-name="fk_inventory_type_event" :initial_value="fk_inventory_type_event" />
            </div>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import InvTypeEventDropdown from '../Dropdowns/InvTypeEventDropdown.vue'
  import { onClose } from '@/mixins/onCloseMixin'
  import messagesMixin from '@/mixins/messagesMixin'

  export default {
    name: 'inventory-type-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        inventory_type_id: null,
        description: null,
        description_en: null,
        fk_inventory_type_event: null,

        modelValidations: {
          description: { required: true, min: 3 },
          description_en: { required: true, min: 3 },
        }
      }
    },

    props: {
      oper: String
    },

    components: {
      InvTypeEventDropdown
    },

    mounted() {
      const { inventory_type_id = null } = this.$route.params
      this.inventory_type_id = inventory_type_id
      if (this.oper === 'upd' && inventory_type_id) {
        this.$store.dispatch('LOAD_INVENTORY_TYPE_BY_ID', inventory_type_id).then((res) => {
          const theInvType = Array.isArray(res) ? res[0] : res
          this.description = theInvType.description
          this.description_en = theInvType.description_en
          this.fk_inventory_type_event = theInvType.fk_inventory_type_event
        })
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const invType = {
          description: this.description,
          description_en: this.description_en,
          fk_inventory_type_event: this.fk_inventory_type_event
        }

        const payload = this.oper === 'add' ? invType : { id: this.inventory_type_id, invType: invType }
        let errDesc = ''
        try {
          await this.$store.dispatch(`${this.oper.toUpperCase()}_INVENTORY_TYPE`, payload)
        } catch (err) {
          errDesc = err.message
        }
        this.savedMessage(errDesc, this.$t('label.inventory_type'), this.description, this.description_en)
        this.onClose()
      }
    },

    computed: {
      saveBtnDisabled() {
        return !this.description || this.description == '' || !this.description_en || this.description_en == '' ||
          !this.fk_inventory_type_event
      }
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>