var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("perm_identity")])],1),_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(`route.inventory_type_${_vm.oper}`))+" ")])]),_c('md-card-content',[_c('div',{staticClass:"md-layout md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
              { 'md-valid': !_vm.errors.has('description') },
              { 'md-error': _vm.errors.has('description') }
            ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.name_ru')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.description),expression:"modelValidations.description"}],attrs:{"type":"text","data-vv-name":"description","required":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('md-field',{class:[
              { 'md-valid': !_vm.errors.has('description_en') },
              { 'md-error': _vm.errors.has('description_en') }
            ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.name_en')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.description_en),expression:"modelValidations.description_en"}],attrs:{"type":"text","data-vv-name":"description_en","required":""},model:{value:(_vm.description_en),callback:function ($$v) {_vm.description_en=$$v},expression:"description_en"}})],1),_c('InvTypeEventDropdown',{attrs:{"label":_vm.$t('label.inventory_event'),"data-vv-name":"fk_inventory_type_event","initial_value":_vm.fk_inventory_type_event},model:{value:(_vm.fk_inventory_type_event),callback:function ($$v) {_vm.fk_inventory_type_event=_vm._n($$v)},expression:"fk_inventory_type_event"}})],1)]),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-success",attrs:{"native-type":"submit","disabled":_vm.saveBtnDisabled},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]),_c('md-button',{staticClass:"md-accent",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClose.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }